:root {
  --accent-color: #abb864;
  --background-color: #fff;
  --base-color: #333;
  --mid-color: #666;
  // --light-color: #999;
  // --red-color: #f36a34;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --accent-color: #abb864;
    --background-color: #212121;
    --base-color: #eee;
    --mid-color: #ccc;
  }
}

// var(--accent-color): #abb864;
// var(--base-color): #333;
// var(--mid-color): #666;
$light-color: #999;
$red-color: #f36a34;

@font-face {
  font-family: CH;
  src: url(fonts/CooperHewitt-Book.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: CH;
  src: url(fonts/CooperHewitt-BookItalic.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: CH;
  src: url(fonts/CooperHewitt-Semibold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: CH;
  src: url(fonts/CooperHewitt-SemiboldItalic.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}

body {
  font-family: CH, Helvetica, arial, sans-serif;
  color: var(--base-color);
  background-color: var(--background-color);
  font-size: 18px;
  line-height: 1.6em;
  letter-spacing: 0.03em;
  text-align: left;
  padding-top: 2.5rem;
  padding-left: 10rem;
  padding-right: 10rem;
  padding-bottom: 5rem;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

code {
  font-family: Menlo, monospace;
}

a {
  color: var(--base-color);
  text-decoration: underline;
  transition: color 0.1s linear;
  -webkit-transition: color 0.1s linear;
  -moz-transition: color 0.1s linear;

  &:visited {
    color: var(--base-color);
  }

  &:hover {
    color: var(--accent-color);
  }

  &:active {
    color: var(--base-color);
  }
}

// p {
// -webkit-hyphens: auto;
// hyphens: auto;

// -webkit-hyphenate-limit-before: 3; /* For Safari */
// -webkit-hyphenate-limit-after: 4; /* For Safari */
// -ms-hyphenate-limit-chars: 10 3 4;
// hyphenate-limit-chars: 10 3 4;
// }

strong {
  font-weight: bold;
}

h3,
h4,
h5,
h6 {
  margin-bottom: -0.5rem;
}

hr {
  border-top: var(--mid-color);
}

th,
td {
  text-align: left;
  padding-right: 1em;
}

blockquote {
  font-size: 16px;
  line-height: 1.4em;
  font-style: italic;
  text-align: justify;

  -webkit-hyphens: auto;
  hyphens: auto;

  -webkit-hyphenate-limit-before: 3;
  /* For Safari */
  -webkit-hyphenate-limit-after: 4;
  /* For Safari */
  -ms-hyphenate-limit-chars: 10 3 4;
  hyphenate-limit-chars: 10 3 4;
}

sup {
  font-size: 12px;
}

.red {
  color: $red-color;
}

.center {
  display: grid;
  place-items: center;

  img {
    width: 80%;
  }
}

.hidden {
  display: none;
}

.nav {
  display: grid;
  grid-template-columns: 2fr 1fr;

  .left {
    grid-column: 1 / 2;

    h1 {
      margin-bottom: 0.1em;
    }

    p {
      margin-top: 0;
    }
  }

  ul {
    display: grid;
    grid-column: 2 / 3;
    place-items: center right;
    list-style: none;
    padding: 0;

    li {
      display: inline;
    }
  }

  .pronounce {
    color: var(--mid-color);
  }
}

.boxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
}

.box {
  border: 1px solid var(--accent-color);
  padding: 1em;

  ul {
    padding: 0;
    list-style: none;
  }
}

.main {
  padding-left: 1em;
  padding-right: 1em;
}

.course {
  margin-bottom: 1em;

  h3 {
    display: inline;
  }

  p {
    display: inline;
  }
}

.blog {
  .post-date {
    text-transform: uppercase;
    color: $light-color;
    font-size: 12px;
    margin-top: -0.25rem;
    margin-bottom: 0;
  }

  .post-title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 0;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .post-subtitle {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 2em;
    line-height: 1.4em;
  }
}

.front-matter {
  border-bottom: 1px dotted $light-color;
  padding-bottom: 0.5em;

  h1 {
    font-size: 24px;
  }

  .post-subtitle {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 1em;
    line-height: 1.4em;
  }

  .post-date {
    text-transform: uppercase;
    font-size: 12px;
    margin-top: -0.25rem;
    margin-bottom: 0;
  }

  .post-date-label {
    @extend .post-date;
    font-weight: bold;
    margin-left: 0.25rem;
  }

  .post-date-content {
    @extend .post-date;
    color: $light-color;
    margin-left: 0.25rem;
  }
}

.article {
  padding-left: 2em;
  padding-right: 2em;

  h2 {
    font-size: 24px;
  }

  .footnotes {
    font-size: 14px;

    ol {
      padding-left: 1em;
    }
  }
}

.bib {
  border-top: 1px solid $light-color;
}

.bibliography {
  padding-left: 4em;
  padding-right: 2em;
  font-size: 14px;
  color: var(--mid-color);
  list-style: square;
}

@media only screen and (max-width: 820px) {
  body {
    font-size: 16px;
    line-height: 1.6em;
    letter-spacing: 0.03em;
    text-align: left;
    padding-top: 2.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .boxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
  }

  .box {
    border: 1px solid var(--accent-color);
    padding-left: 1em;
    padding-top: 0;
    padding-bottom: 0;
  }

  .nav {
    font-size: 18px;

    h1 {
      line-height: 1em;
    }

    .pronounce{
      font-size: 14px;
    }
  }

  .center {
    img {
      width: 100%;
    }
  }

  .article {
    padding-left: 1em;
    padding-right: 1em;

    blockquote {
      margin-left: 1em;
      margin-right: 1.5em;
    }
  }

  .bibliography {
    padding-left: 2em;
  }
}